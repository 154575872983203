import Users from "../pages/users/Users";
import EditUser from "../pages/users/EditUser";
import ViewUser from "../pages/users/ViewUser";
// import Data from '../pages/data/Data';
// import EditData from '../pages/data/EditData';
// import Categories from '../pages/data/Categories';
// import Attributes from '../pages/data/Attributes';
import Gallery from "../pages/gallery/Gallery";
import GalleryEditContent from "../pages/gallery/GalleryEditContent";
import GalleryNewContent from "../pages/gallery/GalleryNewContent";
import Post from "../pages/post/Post";
import Testimonial from "../pages/testimonial/Testimonial";
import EditPost from "../pages/post/EditPost";
import Tag from "../pages/tags/Tag";
import EditTag from "../pages/tags/EditTag";
// import Languages from '../pages/languages/Languages';
import DraftPost from "../pages/post/DraftPost";
// import ImportData from '../pages/importData/ImportData';
import Pages from "../pages/page/Page";
import EditPages from "../pages/page/EditPage";
import HomePage from "../pages/homepage";
import MyYogaPage from "../pages/moja-yoga-prica";
import Inspiration from "../pages/inspiration";
import AktivnaPage from "../pages/aktivna-grupa";
import VipPage from "../pages/vip-grupa";
import PocetnaPage from "../pages/pocetna-grupa";
import {
	UserOutlined,
	FileImageOutlined,
	ReadOutlined,
	BookOutlined,
	HomeOutlined /*, ImportOutlined, ShopOutlined, TranslationOutlined */,
} from "@ant-design/icons";
import EditTestimonial from "../pages/testimonial/EditTestimonial";

export default [
	{
		label: "Users",
		icon: UserOutlined,
		children: [
			{
				label: "All Users",
				path: "/admin/users",
				component: Users,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New User",
				path: "/admin/new-user",
				component: EditUser,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New User Image",
				path: "/admin/new-user/:imgId",
				component: EditUser,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit User Image",
				path: "/admin/edit-user/:imgId/:userId",
				component: EditUser,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit User",
				path: "/admin/edit-user/:userId",
				component: EditUser,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "View User",
				path: "/admin/view-user/:id",
				component: ViewUser,
				allowed: ["admin"],
				showInMenu: false,
			},
		],
	},
	{
		label: "Gallery",
		icon: FileImageOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "GalleryAdd",
				path: "/admin/gallery/edit-content/:fromPage/:itemId",
				component: GalleryEditContent,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Gallery",
				path: "/admin/gallery",
				component: Gallery,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New Content",
				path: "/admin/gallery/new-content",
				component: GalleryNewContent,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	// {
	//   label: 'Data',
	//   icon: ShopOutlined,
	//   children: [
	//     {
	//       label: 'All Data',
	//       path: '/admin/data',
	//       component: Data,
	//       allowed: ['admin'],
	//       showInMenu: true,
	//     },
	//     {
	//       label: 'New Data',
	//       path: '/admin/new-data',
	//       component: EditData,
	//       allowed: ['admin'],
	//       showInMenu: true,
	//     },
	//     {
	//       label: 'New Data Image',
	//       path: '/admin/new-data/:imgId',
	//       component: EditData,
	//       allowed: ['admin'],
	//       showInMenu: false,
	//     },
	//     {
	//       label: 'Edit Data Image',
	//       path: '/admin/edit-data/:imgId/:dataId',
	//       component: EditData,
	//       allowed: ['admin'],
	//       showInMenu: false,
	//     },
	//     {
	//       label: 'Edit Data',
	//       path: '/admin/edit-data/:dataId',
	//       component: EditData,
	//       allowed: ['admin'],
	//       showInMenu: false,
	//     },
	//     {
	//       label: 'Categories',
	//       path: '/admin/data-categories',
	//       component: Categories,
	//       allowed: ['admin'],
	//       showInMenu: true,
	//     },
	//     {
	//       label: 'Categories',
	//       path: '/admin/data-categories/:imgId/:categoryId',
	//       component: Categories,
	//       allowed: ['admin'],
	//       showInMenu: false,
	//     },
	//     {
	//       label: 'Attributes',
	//       path: '/admin/data-attributes',
	//       component: Attributes,
	//       allowed: ['admin'],
	//       showInMenu: true,
	//     },
	//   ],
	// },
	{
		label: "Post",
		icon: ReadOutlined,
		children: [
			{
				label: "All posts",
				path: "/admin/posts",
				component: Post,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New post",
				path: "/admin/new-post",
				component: EditPost,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New post",
				path: "/admin/new-post/:imgId",
				component: EditPost,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit post",
				path: "/admin/edit-post/:postId",
				component: EditPost,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "All DraftPosts",
				path: "/admin/draft-post/:postId",
				component: DraftPost,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit post",
				path: "/admin/edit-post/:imgId/:postId",
				component: EditPost,
				allowed: ["admin"],
				showInMenu: false,
			},

			{
				label: "All tags",
				path: "/admin/tags",
				component: Tag,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New tag",
				path: "/admin/new-tag",
				component: EditTag,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "New tag",
				path: "/admin/new-tag/:imgId",
				component: EditTag,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit tag",
				path: "/admin/edit-tag/:tagId",
				component: EditTag,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit tag",
				path: "/admin/edit-tag/:imgId/:tagId",
				component: EditTag,
				allowed: ["admin"],
				showInMenu: false,
			},
		],
	},
	{
		label: "Pages",
		icon: BookOutlined,
		children: [
			{
				label: "All pages",
				path: "/admin/pages",
				component: Pages,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New page",
				path: "/admin/new-page",
				component: EditPages,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "Edit page",
				path: "/admin/edit-page/:id",
				component: EditPages,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "New page",
				path: "/admin/new-page/:imgId",
				component: EditPages,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit page",
				path: "/admin/edit-page/:imgId/:id",
				component: EditPages,
				allowed: ["admin"],
				showInMenu: false,
			},
		],
	},
	{
		label: "Home Page",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/homepage",
				component: HomePage,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	/////////////////////////////////////////
	{
		label: "My Yoga Page",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/moja-yoga-prica",
				component: MyYogaPage,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	{
		label: "Aktivna grupa",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/aktivna-grupa",
				component: AktivnaPage,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	{
		label: "Vip grupa",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/vip-grupa",
				component: VipPage,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	{
		label: "Pocetna grupa",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/pocetna-grupa",
				component: PocetnaPage,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},
	{
		label: "Inspiration Page",
		icon: HomeOutlined,
		allowed: ["admin"],
		children: [
			{
				label: "Edit content",
				path: "/admin/inspiration",
				component: Inspiration,
				allowed: ["admin"],
				showInMenu: true,
			},
		],
	},

	{
		label: "Testimonial",
		icon: ReadOutlined,
		children: [
			{
				label: "All Testimonials",
				path: "/admin/testimonials",
				component: Testimonial,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New Testimonial",
				path: "/admin/new-testimonial",
				component: EditTestimonial,
				allowed: ["admin"],
				showInMenu: true,
			},
			{
				label: "New Testimonial",
				path: "/admin/new-testimonial/:imgId",
				component: EditTestimonial,
				allowed: ["admin"],
				showInMenu: false,
			},
			{
				label: "Edit Testimonial",
				path: "/admin/edit-testimonial/:id",
				component: EditTestimonial,
				allowed: ["admin"],
				showInMenu: false,
			},

			{
				label: "Edit Testimonial",
				path: "/admin/edit-testimonial/:imgId/:id",
				component: EditTestimonial,
				allowed: ["admin"],
				showInMenu: false,
			},
		],
	},

	//////////////////////////////////////////////////////
];
