import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { HomePageForm } from '../../components/forms';

const HomePage = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [homepage, fetchHomepage] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    fetchHomepage(`${SERVER_URL}/homepage`, {});
  }, [fetchHomepage]);

  const onSubmit = async (formData) => {
    try {
      await Axios.put(`${SERVER_URL}/homepage`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Homepage updated',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/homepage');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      {/* <div className='actions-block'>
        <h2 style={{ textAlign: 'center' }}>Homepage sections</h2>
      </div> */}

      <div>
        {homepage.isLoading && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!homepage.isLoading && !homepage.isError && homepage.data && currentuser.language && (
          <HomePageForm
            homepage={homepage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
          />
        )}

        {!homepage.isLoading && homepage.isError && (
          <h2>Error fetching data :(</h2>
        )}
      </div>
    </div>
  );
};

export default HomePage;
