import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';

const UploadBox = ({ handler, image }) => {
  const imageUrl = image && image.url ? image.url : image;
  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  return (
    <>
      {image ? (
        <img
          src={getUrl(imageUrl)}
          style={{
            width: '100px',
            border: '0.5px solid #bbb',
            background: '#eee',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          alt='upload'
          title='Select from gallery or upload'
          onClick={() => handler()}
        />
      ) : (
        <div
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            border: '0.5px solid #bbb',
            background: '#eee',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          title='Select from gallery or upload'
          onClick={() => handler()}
        >
          <EditOutlined />
          <div>Edit</div>
        </div>
      )}
    </>
  );
};

export default UploadBox;
