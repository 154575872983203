import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Card, Select, Result } from 'antd';
import UploadBox from '../base/UploadBox';

const tailLayout = {
  wrapperCol: { offset: 8, span: 24 },
};

const initialValues = {
  email: undefined,
  password: undefined,
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({ data, updateHandler, createHandler, result, setResult, avatar }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  const prevForm = sessionStorage.getItem('userForm');
  if (prevForm) user = JSON.parse(prevForm);

  let image;
  if (data && data.avatar) image = data.avatar.url;
  if (avatar) image = avatar.url;

  const onFinish = (values, isNew) => {
    sessionStorage.removeItem('userForm');
    if (isNew) {
      if (avatar) {
        values.avatar = avatar._id;
      }
      createHandler(values);
    } else {
      if (data.avatar) values.avatar = data.avatar._id;
      if (avatar) values.avatar = avatar._id;
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const avatarHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('userForm', JSON.stringify(values));
    history.push(`/admin/gallery/edit-content/${isNew ? 'new-user/img' : 'edit-user/' + data._id}`);
  };

  return (
    <div>
      {/* <h2>{isNew ? 'NEW' : 'EDIT'} USER</h2> */}
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NEW' : 'EDIT'} USER`} bordered={false}>
            {!result && (
              <Form name='basic' initialValues={user} onFinish={(values) => onFinish(values, isNew)} onFinishFailed={onFinishFailed} layout='vertical' form={form}>
                <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter email!' }]}>
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item label='First name' name='firstName' rules={[{ required: true, message: 'Please enter first name!' }]}>
                  <Input placeholder='First name' />
                </Form.Item>

                <Form.Item label='Last name' name='lastName' rules={[{ required: true, message: 'Please enter last name!' }]}>
                  <Input placeholder='Last name' />
                </Form.Item>

                <Form.Item label='Address' name='address'>
                  <Input placeholder='Address' />
                </Form.Item>

                <Form.Item label='City' name='city'>
                  <Input placeholder='City' />
                </Form.Item>

                <Form.Item label='Zip' name='zip'>
                  <Input placeholder='Zip' />
                </Form.Item>

                <Form.Item label='Country' name='country'>
                  <Input placeholder='Country' />
                </Form.Item>

                {isNew && (
                  <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
                    <Input.Password placeholder='password' />
                  </Form.Item>
                )}

                <Form.Item label='Role' name='role' rules={[{ required: true, message: 'Please select role!' }]}>
                  <Select mode='multiple' placeholder='Role'>
                    <Select.Option value='admin'>Admin</Select.Option>
                    <Select.Option value='user'>User</Select.Option>
                  </Select>
                </Form.Item>

                {!isNew && (
                  <Form.Item label='Status' name='status' rules={[{ required: true, message: 'Please select status!' }]}>
                    <Select>
                      <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                      <Select.Option value='DELETED'>DELETED</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item label='Avatar' className='upload-wrapper right' name='avatar'>
                  <UploadBox handler={avatarHandler} image={image} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='User has been created and verification mail has been sent'
                extra={
                  <Button type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
