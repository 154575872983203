import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { /* Link, */ useHistory } from 'react-router-dom';
import { notification/* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUser = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [avatar, fetchAvatar] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { userId, imgId } = props.match.params;

  useEffect(() => {
    if (imgId) fetchAvatar(`${SERVER_URL}/images/${imgId}`, {});
    if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`, []);
    }
  }, [userId, imgId, fetchAvatar, fetchUser]);

  const updateUser = async (data) => {
    try {
      await Axios.put(`${SERVER_URL}/users/${userId}`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Item is updated.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/register`, { ...data });
      notification.success({
        message: 'User created.',
        placement: 'bottomRight',
      });
      setResult(true);
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem with creating user. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

      <div style={{ textAlign: 'center' }}>
        {userId && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {userId && !user.isLoading && !user.isError && user.data && user.data.email && <UserForm data={user.data} updateHandler={updateUser} avatar={avatar.data} />}
        {userId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!userId && user && !user.data && <UserForm data={null} createHandler={createUser} result={result} setResult={setResult} avatar={avatar.data} />}
      </div>
    </div>
  );
};

export default EditUser;
