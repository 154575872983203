import React, { useEffect /* , useState */ } from "react";
import { Tabs, Input, Button, Form, Divider, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const MyyogaForm = ({ homepage, language, onSubmit }) => {
	const [form] = Form.useForm();
	// const [tab, setTab] = useState();
	["createdAt", "updatedAt", "__v"].forEach((key) => delete homepage[key]);

	let initialValues = homepage ? homepage : {};

	useEffect(() => {
		// if (language && language.selected) setTab(language.selected.code);
		form.resetFields();
	}, [language, form]);

	return (
		<div className='panel panel-primary'>
			<div className='panel-body'>
				<Form
					initialValues={initialValues}
					onFinish={(values) => onSubmit(values)}
					layout='vertical'
					form={form}
					scrollToFirstError
				>
					<Tabs
						/* onChange={(key) => setTab(key)} */ tabPosition='left'
						defaultActiveKey={
							language ? language.default.code : undefined
						}
					>
						{language.list.map((lang) => (
							<Tabs.TabPane tab={lang.code} key={lang.code}>
								<Form.List name='sections'>
									{(fields, { add, remove }) => (
										<>
											{fields.map((field) => (
												<div key={field.key}>
													<Row gutter={24}>
														<Col flex sm={24} md={6}>
															<Form.Item
																label='Section Title'
																rules={[
																	{
																		required:
																			lang.code ===
																			language.default.code
																				? true
																				: false,
																		message:
																			"Please enter section title!",
																	},
																	({ getFieldValue }) => ({
																		validator(rule, value) {
																			if (
																				getFieldValue(
																					"sections"
																				)
																					.map(
																						(s) =>
																							s.title[
																								lang
																									.code
																							]
																					)
																					.filter(
																						(a, i) =>
																							i !==
																							field.name
																					)
																					.includes(value)
																			) {
																				return Promise.reject(
																					"Section title must be unique!"
																				);
																			}
																			return Promise.resolve();
																		},
																	}),
																]}
																name={[
																	field.name,
																	"title",
																	lang.code,
																]}
																fieldKey={[
																	field.key,
																	"title",
																	lang.code,
																]}
															>
																<Input />
															</Form.Item>
														</Col>

														<Col flex sm={24} md={12}>
															<Form.Item
																label='Section Content'
																rules={[
																	{
																		required:
																			lang.code ===
																			language.default.code
																				? true
																				: false,
																		message:
																			"Please enter section content!",
																	},
																]}
																name={[
																	field.name,
																	"content",
																	lang.code,
																]}
																fieldKey={[
																	field.key,
																	"content",
																	lang.code,
																]}
															>
																<Input.TextArea
																	rows={10}
																	style={{ width: "100%" }}
																/>
															</Form.Item>
														</Col>

														<Col flex sm={24} md={4} style>
															<Button
																danger
																type='primary'
																icon={<MinusCircleOutlined />}
																onClick={() =>
																	remove(field.name)
																}
																disabled={
																	form.getFieldValue(
																		"sections"
																	).length <= 1
																}
															>
																Remove
															</Button>
														</Col>
													</Row>

													<Divider
														style={{
															borderTop: "1.5px solid #ccc",
														}}
													/>
												</div>
											))}

											<Form.Item>
												<Col
													sm={24}
													md={{ span: 16, offset: 4 }}
													lg={{ span: 12, offset: 6 }}
												>
													<Button
														type='ghost'
														onClick={() => add()}
														block
														icon={<PlusOutlined />}
														style={{ background: "#ddd" }}
													>
														Add section
													</Button>
												</Col>
											</Form.Item>
										</>
									)}
								</Form.List>
							</Tabs.TabPane>
						))}
					</Tabs>

					<div className='text-right'>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default MyyogaForm;
